import React from "react";
import { graphql } from "gatsby";

import Helmet from "react-helmet";
import Link from "gatsby-link";
import cx from "classnames";

import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import { StickyContainer, Sticky } from "react-sticky";

import StackGrid from "react-stack-grid";

import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import CircularProgress from "@material-ui/core/CircularProgress";

import siteConfig from "../../data/SiteConfig";

import Page from "../components/Layout/Page";
import {
  FixedWidthContainer,
  FullWidthContainer
} from "../components/Layout/ContentContainers";
import { StyledComponentsBreakpoints } from "../components/Layout/Responsive";

const dayLabelMap = {
  1: "Friday October 25th",
  2: "Saturday October 26th",
  3: "Sunday October 27th"
};
const getDayString = day => {
  return dayLabelMap[day];
};

const getTimeString = event => {
  const { start_string, end_string } = event;
  const leadingZeroRegex = /^0+/;

  return (
    <span>
      <EventTime>
        {start_string.replace(leadingZeroRegex, "")}
        {end_string !== null && (
          <EventTime>
            {` - `}
            {end_string.replace(leadingZeroRegex, "")}
          </EventTime>
        )}
      </EventTime>
    </span>
  );
};

const getHeadlineString = event => {
  const { performer, performer_url, memo } = event;

  if (!performer) {
    return <PerformerName>{memo}</PerformerName>;
  }
  if (!memo && !performer_url) {
    return <PerformerName>{performer}</PerformerName>;
  }

  if (performer_url) {
    let performerName = performer;
    if (performer === "Wolves&wolves&wolves&wolves") {
      performerName = `Wolves & wolves & wolves & wolves`;
    }
    return (
      <PerformerName>
        <Link to={performer_url.replace("https://thefestfl.com", "")}>
          
          {performerName}
        </Link>
        {memo && <PerformerName> ({memo})</PerformerName>}
      </PerformerName>
    );
  }
};
const getMobileTableTimeString = event => {
  const { start_string, end_string } = event;
  const leadingZeroRegex = /^0+/;

  return (
    <span style={{ fontSize: "0.5rem" }}>
      <EventTime>
        {start_string.replace(leadingZeroRegex, "")}
        {end_string !== null && (
          <EventTime>
            {" -"}
            <br />
            {end_string.replace(leadingZeroRegex, "")}
          </EventTime>
        )}
      </EventTime>
    </span>
  );
};

const getMobileTableHeadlineString = event => {
  const { performer, performer_url, memo } = event;
  const FONT_SIZE = "16px";

  if (!performer) {
    return (
      <PerformerName style={{ fontSize: FONT_SIZE }}>{memo}</PerformerName>
    );
  }
  if (!memo && !performer_url) {
    return (
      <PerformerName style={{ fontSize: FONT_SIZE }}>{performer}</PerformerName>
    );
  }

  if (performer_url) {
    return (
      <PerformerName style={{ fontSize: FONT_SIZE }}>
        <Link to={performer_url.replace("https://thefestfl.com", "")}>
          {performer}
        </Link>
        {memo && (
          <PerformerName style={{ fontSize: FONT_SIZE }}>
            {" "}
            ({memo})
          </PerformerName>
        )}
      </PerformerName>
    );
  }
};

const ScheduleByTimeDay = props => {
  const { day, events } = props;

  const dayEvents = events.filter(event => event.day === day);

  return (
    dayEvents.length > 0 && (
      <ScheduleDayContainer>
        <DateHeadline>{getDayString(day)}</DateHeadline>
        <Paper style={{ marginBottom: "2rem" }}>
          <Table>
            <TableHead style={{backgroundColor: "#ddd", textTransform: "uppercase"}}>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>Performer</TableCell>
                <TableCell>Venue</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{backgroundColor: "#fff"}}>
              {dayEvents.map(event => (
                <TableRow key={event.event_id}>
                  <TableCell component="th" scope="row">
                    <PerformerByTime>{getTimeString(event)}</PerformerByTime>
                  </TableCell>
                  <TableCell>{getHeadlineString(event)}</TableCell>
                  <TableCell>
                    <span>{event.venue_name.replace('!', '')}</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </ScheduleDayContainer>
    )
  );
};
const ScheduleByVenueDay = props => {
  const { day, events, venueData } = props;

  const venueMap = {};
  const dayEvents = events.filter(event => event.day === day);

  dayEvents.forEach(event => {
    const matchingVenue = venueData.find(venue => {
      return venue.wordpress_id === event.venue_id;
    });

    if (!matchingVenue) return;

    if (!venueMap[event.venue_name]) {
      const venueInfo = {
        id: event.venue_id,
        name: event.venue_name,
        events: [event],

        address: matchingVenue.acf.address.address,
        fest_day_1_notes: matchingVenue.acf.fest_day_1_notes,
        fest_day_2_notes: matchingVenue.acf.fest_day_2_notes,
        fest_day_3_notes: matchingVenue.acf.fest_day_3_notes
      };

      venueMap[event.venue_name] = venueInfo;
    } else {
      venueMap[event.venue_name].events.push(event);
    }
  });

  return (
    dayEvents.length > 0 && (
      <ScheduleByVenueDayContainer>
        <DateHeadline>{getDayString(day)}</DateHeadline>

        <StackGrid
          component="ul"
          itemComponent="li"
          columnWidth={480}
          gutterWidth={16}
          gutterHeight={24}
        >
          {Object.keys(venueMap)
            .sort()
            .map(venue_name => {
              const venue = venueMap[venue_name];
              const festDay = day;
              const noteKey = `fest_day_${festDay}_notes`;

              return (
                <div key={`${venue_name}_${day}`} style={{ textAlign: "left" }}>
                  <Card key={venue.id}>
                    <CardContent style={{ backgroundColor: "#eee", padding: 0 }}>
                      <div
                        style={{
                          backgroundColor: "#ddd",
                          padding: "1rem 1.2rem 0.666rem"
                        }}
                      >
                        <ScheduleByVenueVenueLabel>
                          {venue.name.replace('!', '')}
                        </ScheduleByVenueVenueLabel>
                        <ScheduleByVenueAddressLabel>
                          <a target="_blank" href={`https://www.google.com/maps/place/${venue.address}`}>{venue.address}</a>
                        </ScheduleByVenueAddressLabel>
                      </div>

                      <div style={{ padding: "0 0rem 1.5rem" }}>
                        <ScheduleByVenueVenueEventList>
                          {venue.events.reverse().map(event => (
                            <ScheduleByVenueEventRow key={event.event_id}>
                              <ScheduleByVenueEventTime>
                                {getTimeString(event)}
                              </ScheduleByVenueEventTime>
                              <ScheduleByVenueEventPerformer>
                                <strong>{getHeadlineString(event)}</strong>
                              </ScheduleByVenueEventPerformer>
                            </ScheduleByVenueEventRow>
                          ))}
                        </ScheduleByVenueVenueEventList>
<hr />
                        <ScheduleByVenueNoteContainer>
                          {venue[noteKey] &&
                            venue[noteKey].split("\n").map(function(item) {
                              return (
                                <p
                                  key={item.substr(0, 5)}
                                  dangerouslySetInnerHTML={{
                                    __html: `${item}`
                                  }}
                                />
                              );
                            })}
                        </ScheduleByVenueNoteContainer>

                      </div>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
        </StackGrid>
      </ScheduleByVenueDayContainer>
    )
  );
};
const MobileScheduleByTimeDay = props => {
  const { day, events } = props;

  const dayEvents = events.filter(event => event.day === day);

  return (
    dayEvents.length > 0 && (
      <ScheduleDayContainer style={{ marginBottom: "3rem" }}>
        <DateHeadline style={{ padding: "0 1rem" }}>
          {getDayString(day)}
        </DateHeadline>
        <Table style={{ tableLayout: "fixed" }}>
          <TableBody style={{backgroundColor: "#eee"}}>
            {dayEvents.map(event => (
              <TableRow key={event.event_id}>
                <TableCell
                  scope="row"
                  style={{
                    padding: "0.6rem 5% 0.6rem 1rem",
                    width: "28%",
                    verticalAlign: "top"
                  }}
                >
                  {getMobileTableTimeString(event)}
                </TableCell>
                <TableCell
                  style={{
                    padding: "0.6rem 1rem 0.6rem 0",
                    verticalAlign: "top"
                  }}
                >
                  {getMobileTableHeadlineString(event)}
                  <br />
                  <span
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 400,
                      color: "#666"
                    }}
                  >
                    {event.venue_name.replace('!', '')}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScheduleDayContainer>
    )
  );
};
const MobileScheduleByVenueDay = props => {
  const { day, events, venueData } = props;

  const venueMap = {};
  const dayEvents = events.filter(event => event.day === day);

  dayEvents.forEach(event => {
    const matchingVenue = venueData.find(venue => {
      return venue.wordpress_id === event.venue_id;
    });

    if (!matchingVenue) return;
    if (!venueMap[event.venue_name]) {
      const venueInfo = {
        id: event.venue_id,
        name: event.venue_name,
        events: [event],
        address: matchingVenue.acf.address.address,
        fest_day_1_notes: matchingVenue.acf.fest_day_1_notes,
        fest_day_2_notes: matchingVenue.acf.fest_day_2_notes,
        fest_day_3_notes: matchingVenue.acf.fest_day_3_notes
      };

      venueMap[event.venue_name] = venueInfo;
    } else {
      venueMap[event.venue_name].events.push(event);
    }
  });

  return (
    dayEvents.length > 0 && (
      <ScheduleByVenueDayContainer>
        <DateHeadline>{getDayString(day)}</DateHeadline>
        <ul style={{ listStyleType: "none" }}>
          {Object.keys(venueMap)
            .sort()
            .map(venue_name => {
              const venue = venueMap[venue_name];
              const festDay = day;
              const noteKey = `fest_day_${festDay}_notes`;

              return (
                <li
                  key={`${venue_name}_${day}`}
                  style={{
                    textAlign: "left",
                    margin: "0 0 2rem",
                    backgroundColor: "#eee"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ddd",
                      padding: "1rem"
                    }}
                  >
                    <ScheduleByVenueVenueLabel>
                      {venue.name.replace('!', '')}
                    </ScheduleByVenueVenueLabel>
                    <ScheduleByVenueAddressLabel>
                    <a target="_blank" href={`https://www.google.com/maps/place/${venue.address}`}>{venue.address}</a>
                    </ScheduleByVenueAddressLabel>
                  </div>

                  <div style={{ padding: "0 1rem" }}>
                    <ScheduleByVenueVenueEventList>
                      {venue.events.reverse().map(event => (
                        <ScheduleByVenueEventRow key={event.event_id}>
                          <ScheduleByVenueEventTime style={{ flex: "45% 1 1" }}>
                            {getTimeString(event)}
                          </ScheduleByVenueEventTime>
                          <ScheduleByVenueEventPerformer>
                            <strong>{getHeadlineString(event)}</strong>
                          </ScheduleByVenueEventPerformer>
                        </ScheduleByVenueEventRow>
                      ))}
                    </ScheduleByVenueVenueEventList>

                    <ScheduleByVenueNoteContainer>
                      {venue[noteKey] &&
                        venue[noteKey].split("\n").map(function(item) {
                          return (
                            <p
                              key={item.substr(0, 5)}
                              dangerouslySetInnerHTML={{
                                __html: `${item}`
                              }}
                            />
                          );
                        })}
                    </ScheduleByVenueNoteContainer>
                  </div>
                </li>
              );
            })}
        </ul>
      </ScheduleByVenueDayContainer>
    )
  );
};

class SchedulePage extends React.Component {
  constructor(props) {
    super(props);

    
    this.state = {
      items: [],
      error: null,
      isLoaded: false,
      performerNameSearchInputValue: "",
      sortBy: "venue"
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    fetch("https://api.thefestfl.com/fest22/events")
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  getMetaUrl() {
    let baseUrl = siteConfig.siteUrl;

    return `${baseUrl}/schedule`;
  }

  getMetaTitle() {
    let pageTitle = siteConfig.siteTitle;

    pageTitle = `Schedule | ${pageTitle}`;
    return pageTitle;
  }

  handleInputChange = event => {
    const searchTerm = event.target.value;

    this.setState({
      performerNameSearchInputValue: searchTerm
    });
  };

  handleSelectChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    if (1 === 0) {
      return (
        <Page title="The FEST 22 Schedule">
          <Helmet title={this.getMetaTitle()}>
            <meta property="og:url" content={this.getMetaUrl()} />
            <meta property="og:title" content={this.getMetaTitle()} />
          </Helmet>
          <FixedWidthContainer>
            <h2>COMING LATER</h2>
            <p>CHECK BACK CLOSER TO FEST WEEKEND!</p>
          </FixedWidthContainer>
        </Page>
      );
    }

    const { isLoaded, error, items } = this.state;

    const venueData = this.props.data.allWordpressWpScheduleVenues.edges.map(
      venue => venue.node
    );

    if (isLoaded && !error) {
      const events = items.filter(item => {
        if (!item.start_time) return false;

        if (this.state.performerNameSearchInputValue.length > 2) {
          if (
            (item.memo || "")
              .toLowerCase()
              .indexOf(this.state.performerNameSearchInputValue.toLowerCase()) <
              0 &&
            (item.performer || "")
              .toLowerCase()
              .indexOf(this.state.performerNameSearchInputValue.toLowerCase()) <
              0
          ) {
            return false;
          }
        }

        return true;
      });

      return (
        <Page title="The FEST 22 Schedule">
          <Helmet title={this.getMetaTitle()}>
            <meta property="og:url" content={this.getMetaUrl()} />
            <meta property="og:title" content={this.getMetaTitle()} />
          </Helmet>
          <Phone>
            <FormControl style={{ minWidth: 120, padding: "1rem 1rem 0" }}>
              <strong>View:</strong>
              <Select
                value={this.state.sortBy}
                onChange={this.handleSelectChange("sortBy")}
                style={{backgroundColor: "#fff"}}
                native
                autoWidth
              >
                <option value="venue">List by venue</option>
                <option value="time">List by time</option>
              </Select>
            </FormControl>
            <SearchFieldContainer style={{ marginBottom: "2rem" }}>
              <TextField
                id="name"
                label="Search for a performer"
                value={this.state.performerNameSearchInputValue}
                onChange={this.handleInputChange}
                fullWidth
              />
            </SearchFieldContainer>

            {this.state.sortBy === "time" && (
              <FullWidthContainer>
                <div>
                  <MobileScheduleByTimeDay day={1} events={events} />
                  <MobileScheduleByTimeDay day={2} events={events} />
                  <MobileScheduleByTimeDay day={3} events={events} />
                </div>
              </FullWidthContainer>
            )}
            {this.state.sortBy === "venue" && (
              <FullWidthContainer>
                <div>
                  <MobileScheduleByVenueDay
                    day={1}
                    events={events}
                    venueData={venueData}
                  />
                  <MobileScheduleByVenueDay
                    day={2}
                    events={events}
                    venueData={venueData}
                  />
                  <MobileScheduleByVenueDay
                    day={3}
                    events={events}
                    venueData={venueData}
                  />
                </div>
              </FullWidthContainer>
            )}
          </Phone>
          <NotPhone>
            <FullWidthContainer
              style={{ marginBottom: "72px", textAlign: "center" }}
            >
              <FormControl style={{ minWidth: 120, padding: "1rem 1rem 0" }}>
                <Select
                  value={this.state.sortBy}
                  onChange={this.handleSelectChange("sortBy")}
                  style={{backgroundColor: "#fff"}}
                  native
                  autoWidth
                >
                  <option value="venue">List by venue</option>
                  <option value="time">List by time</option>
                </Select>
              </FormControl>
              <SearchFieldContainer style={{  marginBottom: "2rem" }}>
                <TextField
                  id="name"
                  label="Search for a performer"
                  value={this.state.performerNameSearchInputValue}
                  onChange={this.handleInputChange}
                  fullWidth
                />
              </SearchFieldContainer>

              {this.state.sortBy === "time" && (
                <FixedWidthContainer>
                  <div>
                    <ScheduleByTimeDay day={1} events={events} />
                    <ScheduleByTimeDay day={2} events={events} />
                    <ScheduleByTimeDay day={3} events={events} />
                  </div>
                </FixedWidthContainer>
              )}

              {this.state.sortBy === "venue" && (
                <FullWidthContainer>
                  <div>
                    <ScheduleByVenueDay
                      day={1}
                      events={events}
                      venueData={venueData}
                    />
                    <ScheduleByVenueDay
                      day={2}
                      events={events}
                      venueData={venueData}
                    />
                    <ScheduleByVenueDay
                      day={3}
                      events={events}
                      venueData={venueData}
                    />
                  </div>
                </FullWidthContainer>
              )}
            </FullWidthContainer>
          </NotPhone>
        </Page>
      );
    }

    return (
      <Page title={`THE FEST 22 Schedule`}>
        <Helmet title={this.getMetaTitle()}>
          <meta property="og:url" content={this.getMetaUrl()} />
          <meta property="og:title" content={this.getMetaTitle()} />
        </Helmet>
        <FixedWidthContainer
          style={{ marginBottom: "72px", textAlign: "center" }}
        >
          <CircularProgress
            size={66}
            style={{ color: "#eb008f" }}
            thickness={5}
          />
          <h4>Loading...</h4>
        </FixedWidthContainer>
      </Page>
    );
  }
}

/* eslint no-undef: "off" */

/* eslint no-undef: "off" */

export const pageQuery = graphql`
query ScheduleQuery {
  allWordpressWpScheduleEvents {
    edges {
      node {
        id
        wordpress_id
        title
        acf {
          performer {
            post_title
            post_name
          }
          memo
          start_time
          end_time
        }
      }
    }
  }

  allWordpressWpScheduleVenues {
    edges {
      node {
        id
        wordpress_id
        title
        acf {
          address {
            address
          }
          fest_day_1_events
          fest_day_1_notes
          fest_day_2_events
          fest_day_2_notes
          fest_day_3_events
          fest_day_3_notes
        }
      }
    }
  }
}
`;


const Phone = styled.div`
  ${StyledComponentsBreakpoints.Phone`
    display:inherit;
  `};

  ${StyledComponentsBreakpoints.NotPhone`
    display:none;
  `};
`;
const NotPhone = styled.div`
  ${StyledComponentsBreakpoints.Phone`
    display:none;
  `};

  ${StyledComponentsBreakpoints.NotPhone`
    display:inherit;
  `};
`;
const fadeInAnimation = keyframes`${fadeIn}`;

const SearchFieldContainer = styled.div`
  display: inline-block;
  width: 280px;

  max-width: 95%;
  padding-left: 1rem;
  margin-right: 1rem;

  .MuiInputLabel-formControl { 
    color: #ddd;
  }
  .MuiInputBase-input {
    color: #eee;
  }

  @media (max-width: 840px) {
    width: 220px;
  }

  ${StyledComponentsBreakpoints.Phone`
    display: block;
    line-height: 0;
    width: 100%;
    margin: 1rem 0 0;
    
    text-align: left;
  `};
`;

const ScheduleDayContainer = styled.div`
  font-size: 16px;
`;
const DateHeadline = styled.h2`
  margin-bottom: 1rem;
  max-width: inherit !important;
`;
const EventTime = styled.span`
  text-transform: uppercase;
  font-style: italic;
`;
const PerformerName = styled.span`
   text-transform: uppercase;
  
  font-size: 16px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "segoe ui", arial,
    "ubuntu", sans-serif !important;
  font-weight: normal;
  line-height: 20px;
  color: #222;

  a {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "segoe ui", arial,
      "ubuntu", sans-serif !important;
    font-weight: bold;
    font-size: 16px;
    color: #111;
  }
`;

// by venue
const ScheduleByVenueDayContainer = styled.div`
  margin: 1rem 0 3rem;
  text-align: center;
`;
const ScheduleByVenueVenueLabel = styled.h3`
  text-transform: uppercase;
  font-family: "League Gothic", -apple-system, BlinkMacSystemFont,
    "avenir next", avenir, helvetica, "helvetica neue", Ubuntu, "segoe ui",
    arial, sans-serif;
  font-size: 36px;
  color: #111;

`;

const ScheduleByVenueAddressLabel = styled.h4`
  text-transform: uppercase;
  font-family: "League Gothic", -apple-system, BlinkMacSystemFont,
    "avenir next", avenir, helvetica, "helvetica neue", Ubuntu, "segoe ui",
    arial, sans-serif;

  font-size: 20px;
  margin-top: -6px;


  a { 
    color: #666 !important;
  }


`;

const ScheduleByVenueVenueEventList = styled.ul`
  margin: 1rem auto 1rem;

  span {
    font-size: 13px;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "segoe ui", arial,
      "ubuntu", sans-serif !important;
    font-weight: normal;
    line-height: 20px;
    color: #222;

    a {
      font-family: "Roboto", -apple-system, BlinkMacSystemFont, "segoe ui", arial,
        "ubuntu", sans-serif !important;
      font-weight: bold;
      font-size: 14px;
      color: #111;
    }
  }
`;
const ScheduleByVenueEventRow = styled.li`
  display: flex;
`;
const ScheduleByVenueEventTime = styled.div`
  flex: 30% 1 1;
  margin-right: 2%;

  span {
    font-weight: 400;
  }
`;
const ScheduleByVenueEventPerformer = styled.div`
  flex: 68% 1 1;
`;

const ScheduleByVenueNoteContainer = styled.div`


  p {
    font-size: 14px;
    margin: 0 1rem -0.5rem;
    color: #111;

    a {
      font-weight: 800;
      font-size: 18px;
      color: #333 !important;
      display: block;
      margin-top: 12px;
    }
  }
`;

const PerformerByTime = styled.span`
`;

export default SchedulePage;
